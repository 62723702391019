<template>
  <a-table rowKey="id" :data-source="list" :pagination="pagination" :loading="listLoading" @change="handleTableChange">
    <a-table-column key="coverUrl" title="商品图片" align="center" width="120px">
      <template slot-scope="text, record">
        <a-avatar style="width: 142px; height: 80px" shape="square" :src="record.coverUrl"></a-avatar>
      </template>
    </a-table-column>
    <a-table-column key="goodsName" title="商品名称" align="center" data-index="goodsName" width="120px" />
    <a-table-column key="classification" title="分类" align="center" data-index="classification" width="120px" />
    <a-table-column key="sellPrice" title="价格" align="center" data-index="sellPrice" width="80px" />
    <a-table-column key="-" title="已售" align="center" data-index="-" width="80px" />
    <a-table-column key="creator" title="创建者" align="center" data-index="creator" width="120px" />
    <a-table-column key="createTimestamp" title="添加时间" align="center" data-index="createTimestamp" width="120px" />
    <a-table-column
      key="modifiedTimestamp"
      title="最后操作时间"
      align="center"
      data-index="modifiedTimestamp"
      width="120px"
    />
    <a-table-column key="status" title="状态" align="center" width="80px">
      <template slot-scope="record">
        <span>
          {{ record.status | GoodsSkillStatusText() }}
        </span>
      </template>
    </a-table-column>
    <a-table-column key="actions" title="操作" align="center" width="200px">
      <template slot-scope="record">
        <a-space>
          <a-button
            v-if="$auth('goods_skill_select')"
            type="link"
            size="small"
            @click="
              $router.push({
                path: '/goods/skill/detail/' + record.id
              })
            "
            ><a-icon type="eye" />查看</a-button
          >
          <a-button
            type="link"
            size="small"
            v-show="$auth('goods_skill_update') && record.status !== 'ON_SHELF'"
            @click="
              $router.push({
                path: '/goods/skill/edit/' + record.id
              })
            "
            ><a-icon type="edit" />编辑</a-button
          >
          <a-button
            type="link"
            size="small"
            v-show="$auth('goods_skill_delete') && record.status == 'CREATED'"
            @click="handleRemove(record)"
            class="error-color"
            ><a-icon type="delete" />删除</a-button
          >
          <a-button v-if="$auth('goods_skill_status')" type="link" size="small" @click="handleStatus(record)">{{
            record.status == 'ON_SHELF' ? '下架' : '上架'
          }}</a-button>
        </a-space>
      </template>
    </a-table-column>
  </a-table>
</template>

<script>
import * as goodsskillApi from '@/api/goodsskill'
export default {
  data() {
    return {
      listLoading: false,
      list: [],
      query: {},
      pagination: {}
    }
  },
  mounted() {},
  methods: {
    initData(query = {}) {
      this.query = query
      this.pagination = this.initPagination()
      return this.getData()
    },
    getData() {
      this.listLoading = true
      const {
        pagination: { current: pageIndex, pageSize },
        query
      } = this
      return goodsskillApi
        .list({ ...query, pageIndex, pageSize })
        .then(res => {
          const { list, totalItems: total } = res.data
          this.list = list
          this.pagination.total = total
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    initPagination() {
      return {
        current: 1,
        pageSize: 10,
        showTotal: total => {
          const h = this.$createElement
          const {
            pagination: { pageSize }
          } = this
          return h('div', {}, [
            h('span', '共 '),
            h('span', { class: 'error-color' }, pageSize ? Math.ceil(total / pageSize) : Math.ceil(total / 10)),
            h('span', ' 页'),
            h('span', ' / '),
            h('span', { class: 'error-color' }, total),
            h('span', ' 条数据')
          ])
        },
        showSizeChanger: true,
        total: 0,
        pageSizeOptions: ['10', '20', '50', '100']
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const { getData } = this

      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize

      getData()
    },
    handleRemove(record) {
      const confirm = this.$confirm({
        title: '删除',
        content: '您确定要删除吗',
        onOk: () => {
          return goodsskillApi
            .remove(record.id)
            .then(res => {
              const { code } = res
              if (code === 0) {
                this.$notification['success']({ message: '操作成功' })
                this.getData()
              } else {
                this.$notification['error']({ message: '操作失败' })
              }
            })
            .catch(err => {
              confirm.destroy()
              this.$info({
                title: '不可删除',
                content: err.msg
              })
            })
        }
      })
    },
    handleStatus(record) {
      const nextStatusMap = {
        CREATED: {
          title: '确认提示',
          content: '您确定要上架此商品吗'
        },
        ON_SHELF: {
          title: '确认提示',
          content: '您确定要下架此商品吗'
        },
        OFF_SHELF: {
          title: '确认提示',
          content: '您确定要上架此商品吗'
        }
      }
      const { id, status } = record
      const nextStatus = nextStatusMap[status]
      const confirm = this.$confirm({
        ...nextStatus,
        onOk: () => {
          return goodsskillApi
            .switchStatus(id)
            .then(res => {
              const { code } = res
              if (code === 0) {
                this.$notification['success']({ message: '操作成功' })
                this.getData()
              } else {
                this.$notification['error']({ message: '操作失败' })
              }
            })
            .catch(err => {
              confirm.destroy()
              this.$info({
                title: '操作失败',
                content: err.msg
              })
            })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-btn {
  font-size: 12px;
}
</style>
